import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '../../environments/environment';

@Injectable()
export class AppInsightService {
    private angularPlugin = new AngularPlugin();
    private appInsights: ApplicationInsights;

    constructor(private router: Router) {
        this.appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: environment.appInsights.instrumentationKey,
            extensions: [this.angularPlugin],
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router,
                    errorServices: [new ErrorHandler()],
                },
            },
        },
    });
        this.appInsights.loadAppInsights();
    }

    trackEvent(name: string): void {
        this.appInsights.trackEvent({ name });
    }

    trackTrace(message: string): void {
        this.appInsights.trackTrace({ message });
    }
}
