import { ErrorHandler, NgModule } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { AppInsightService } from './appinsights.service';


@NgModule({
  declarations: [],
  imports: [],
  providers: [
    AppInsightService,
    {
        provide: ErrorHandler,
        useClass: ApplicationinsightsAngularpluginErrorService,
      }
  ]
})
export class InsightsModule {
    constructor(private appInsightService: AppInsightService) {}
}
