<div [ngClass]="theme">
    <xds-header [attr.application-name]="brandName + ' Client Credential Management System'" dashboard-link="/"
        initials={{initials}}>
        <div slot='brand' class="brand-logo">
            <img *ngIf="!brand" [src]="'/assets/logos/mr.png'" height="45px"/>
            <img *ngIf="brand" [src]="'/assets/logos/' + brand + '.png'" height="45px"/>
        </div>
        <div slot="optional" style="display: flex; flex-direction: row; gap: 0">
            <xds-dropdown same-size="false" inline placement="bottom-end" visible-items="10"
                style="color: var(--xds-color-grey-dark)">
                <xds-button slot="trigger" variant="minimal" dark icon-only><xds-icon slot="icon" name="information-2"
                        size="m"></xds-icon></xds-button>
                <xds-dropdown-item class="xds:is-divider" disabled>Application</xds-dropdown-item>
                <xds-dropdown-item value="1" disabled>Version: {{ 'Version' | translate }}</xds-dropdown-item>
                <xds-dropdown-item class="xds:is-divider" disabled>User roles</xds-dropdown-item>
                <xds-dropdown-item [value]="i + 1" disabled *ngFor="let role of roles; let i = index">{{role}}</xds-dropdown-item>
                <xds-dropdown-item class="xds:is-divider" disabled>Other</xds-dropdown-item>
                <xds-dropdown-item *ngIf="isLoggedIn" [value]="roles.length + 2" (click)="isLoggedIn ? logout() : login()">Logout</xds-dropdown-item>
            </xds-dropdown>
        </div>
    </xds-header>
    <xds-sidebar>
        <xds-sidenav slot="sidebar">
            <xds-sidenav-item *ngFor="let navItem of data" [attr.is-active]="isSelected(navItem.link)">
                <a [routerLink]="[navItem.link]">
                    <xds-sidenav-item-content [icon]="navItem.icon" [label]="navItem.name"></xds-sidenav-item-content>
                </a>
            </xds-sidenav-item>
        </xds-sidenav>
        <router-outlet></router-outlet>
    </xds-sidebar>
    <div class="version"></div>
    <div *ngIf="!isLoggedIn" class="loginWrapper">
        <div class="welcomeMessage">
            <div>
                {{ 'messages.welcomeLogin' | translate }}
            </div>
            <a class="coloredButton" (click)="login()">{{ 'messages.login' | translate }} </a>
        </div>
    </div>
</div>
