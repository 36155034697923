export const environment = {
    production: true,
    urlPrefix: '',
    tenant: 'byoidpoc.onmicrosoft.com',
    tenantId: '9f2c9c2d-da50-4f33-8dfb-a780f38b50dd',
    clientID: '6edde53d-1665-4704-91d9-80d35ef20bea',
    graphScopes: ['openid'],
    signInPolicy: 'B2C_1A_StdSignInOnlyMfa',
    b2cScopes: ['6edde53d-1665-4704-91d9-80d35ef20bea'],
    authoritySignIn:
        'https://byoidpoc.b2clogin.com/byoidpoc.onmicrosoft.com/B2C_1A_StdSignInOnlyMfa',
    appInsights: {
        instrumentationKey: '2eb614d8-2fea-4089-b73b-746a61106cd7'
    }
};
