export type Role = 'CustomerAdmin' | 'APIAdmin' | 'ApiAdmin' | 'APIMManager';

export const ROLE_CLAIM = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export interface IInviteSystemRoleConfiguration {
    displayName: string,
    permittedUserRoles: Role[]
}

export interface IInviteSystemConfiguration {
    displayName: string,
    roles: IInviteSystemRoleConfiguration[]
}
