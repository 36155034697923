<div>
    <div>
        <h1>{{ 'messages.addNewApplication' | translate }}</h1>
        <p>This is the application that will be consuming our APIs.</p>
    </div>
    <div>
        <div>
            <div>
                <div *ngIf="displayErrorMessage.length">
                    <xds-notification variant="error" text="Error Lorem Ipsum"></xds-notification>
                    <div>{{displayErrorMessage}}</div>
                </div>

                <form [formGroup]="applicationForm" (ngSubmit)="onSubmit()">
                    <xds-stack space="1rem">
                        <xds-input-text helper-text="Provide an application name"
                                        type="text"
                                        formControlName="applicationApiName"
                                        [attr.has-error]="validationResult.isApplicationApiNameInvalid"
                                        [attr.error-text]="validationResult.applicationApiNameInvalidMessage"> Application Name </xds-input-text>

                        <xds-input-text helper-text="Provide a company or customer name"
                                        type="text"
                                        formControlName="customer"
                                        [attr.has-error]="validationResult.isCustomerInvalid"
                                        [attr.error-text]="validationResult.customerInvalidMessage"> Company or Customer Name </xds-input-text>

                        <xds-select label="Environment"
                                    [attr.value]="environment"
                                    id="environment"
                                    visible-items="8"
                                    formControlName="environment">
                            <xds-dropdown-item slot="items" *ngFor="let environment of environments"
                                               [attr.value]="environment">{{environment | translate}}</xds-dropdown-item>
                        </xds-select>
                        <p>
                            Your application name is: <b>{{displayName$ | async}}</b>
                        </p>
                        <xds-notification variant="error"
                                          *ngIf="validationResult.isDisplayNameInvalid"
                                          [text]="validationResult.displayNameInvalidMessage"></xds-notification>
                        <xds-button variant="primary" type="submit" [disabled]="applicationForm.invalid">{{ 'messages.addApplication' | translate }}</xds-button>
                    </xds-stack>
                </form>
            </div>
        </div>
    </div>
</div>
