import { catchError, EMPTY, finalize, Observable } from "rxjs";
import { ApplicationService } from "src/app/services/application.service";

import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';
import { MsalService } from "src/app/shared/authentication/msal.service";
import { IApplication } from "src/app/models/IApplication";

@Component({
    selector: 'app-applications',
    templateUrl: './applications.component.html',
    styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {
    private translations: any;
    canCreateApplication: boolean = false;
    applications$: Observable<IApplication[]>;
    isLoading: boolean = true;

    constructor(
        private appService: ApplicationService,
        private translate: TranslateService,
        private toastr: ToastrService,
        private msalService: MsalService
    ) {
        this.initI18nData();
    }

    ngOnInit() {
        this.canCreateApplication = this.msalService.hasAnyRole(["APIAdmin", "APIMManager"]);

        this.applications$ = this.appService.getApplications(null).pipe(
            catchError(error => {
                console.error(error);
                this.toastr.error(this.translations.noDataFound);
                return EMPTY;
            }),
            finalize(() => this.isLoading = false)
        );
    }

    private initI18nData() {
        this.translate.get('errors').subscribe(data => {
            this.translations = data;
        });
    }
}
