import { IAdministratorAppRoleAssignment } from "src/app/models/IAdministratorAppRoleAssignment";
import { IApplication } from "src/app/models/IApplication";
import { ICreateAppRoleAssignment } from "src/app/models/ICreateAppRoleAssignment";
import { ApplicationService } from "src/app/services/application.service";
import { UserService } from "src/app/services/user.service";

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, catchError, combineLatest, EMPTY, finalize, forkJoin, map, Observable, of, Subject, switchMap, takeUntil, tap, toArray } from "rxjs";
import { MsalService } from "../../../shared/authentication/msal.service";
import { Role } from "../../../common/custom.types";
import { ICreatePasswordCredential } from "../../../models/ICreatePasswordCredential";
import { TXdsSelectOption } from "@xds/components";

type DataToRefresh = 'Application' | 'Administrators' | 'All';

interface IPasswordCredentialVM {
    keyId: string,
    displayName: string,
    startDate: Date,
    endDate: Date,
    remainingDays: number
}

interface IApplicationVM {
    displayName: string,
    applicationId: string,
    objectId: string,
    passwordCredentials: IPasswordCredentialVM[]
}

@Component({
    selector: 'app-application-details',
    templateUrl: './application-details.component.html',
    styleUrls: ['./application-details.component.scss']
})
export class ApplicationDetailsComponent implements OnInit {
    private application: IApplication;
    applicationVM: IApplicationVM;
    id: any;
    isLoading: boolean = false;
    private translations: any;
    isNewSecretCreated: boolean;
    generatedSecret: string;
    administrators: IAdministratorAppRoleAssignment;
    currentKeyId: string;
    isDeleteSecretInProgress: boolean = false;
    private destroy$ = new Subject<void>();
    private refreshApplicationData$ = new BehaviorSubject<DataToRefresh>('All');
    
    isAdmin: boolean = false;

    durationOptions: TXdsSelectOption[] = [
        {
            text: '',
            value: null
        },
        {
            text: '1 year',
            value: '1'
        },
        {
            text: '2 years',
            value: '2'
        },
    ]

    @ViewChild('confirmDeletion') confirmDeletionModal: ElementRef;

    passwordCredentialForm = new FormGroup({
        displayName: new FormControl<string | null>(null, Validators.required),
        endDate: new FormControl<Date | null>(null, Validators.required),
        duration: new FormControl<string | null>(null, Validators.required)
    });

    customerAdminForm = new FormGroup({
        email: new FormControl<string | null>(null, [Validators.required, Validators.email])
    });

    apiAdminForm = new FormGroup({
        email: new FormControl<string | null>(null, [Validators.required, Validators.email])
    });

    constructor(
        private appService: ApplicationService,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private userService: UserService,
        private toastr: ToastrService,
        private msalService: MsalService
    ) {}

    ngOnInit(): void {
        this.initI18nData();
        this.initPasswordCredentialFormEvents();

        this.msalService.getUserRoles$().pipe(
            toArray(),
            takeUntil(this.destroy$)
        ).subscribe(roles => this.calculateRoles(roles));

        const paramId$ = this.route.params.pipe(
            map(params => params.id as string),
            tap(id => this.id = id)
        );

        combineLatest({
            id: paramId$,
            refresh: this.refreshApplicationData$.asObservable()
        }).pipe(
            tap(() => this.isLoading = true),
            switchMap(data => forkJoin({
                application: data.refresh === 'All' || data.refresh === 'Application' ? this.getApplicationByAppId$(data.id) : of(this.application),
                administrators: data.refresh === 'All' || data.refresh === 'Administrators' ? this.getAdmins$(data.id) : of(this.administrators)
            })),
            takeUntil(this.destroy$)
        ).subscribe(data => {
            this.administrators = data.administrators;
            this.application = data.application;
            this.isLoading = false;
            this.refreshApplicationVM(this.application);
        });
    }

    refreshApplicationVM(application: IApplication): void {
        this.applicationVM = {
            applicationId: application?.applicationId,
            displayName: application?.displayName,
            objectId: application?.id,
            passwordCredentials: application?.passwordCredentials?.map(p => {
                return {
                    displayName: p.displayName,
                    keyId: p.keyId,
                    startDate: p.startDate,
                    endDate: p.endDate,
                    remainingDays: this.credentialRemaining(p.endDate)
                };
            }) ?? []
        };
    }

    calculateRoles(roles: string[]): void {
        if (!roles) {
            return;
        }
        const adminRoles: Role[] = ['APIAdmin', 'APIMManager'];
        this.isAdmin = adminRoles.map(r => r.toString()).some(r => roles.includes(r));
    }

    private getApplicationByAppId$(id: string): Observable<IApplication> {
        return this.appService.getApplicationByAppId(id).pipe(
            tap(application => console.log(application)),
            catchError(error => {
                console.error(error);
                if (error.status === 403) {
                    this.toastr.error(this.translations.noPermissions);
                    this.router.navigate(['/']);
                } else {
                    this.toastr.error(this.translations.appNotFound);
                }
                return EMPTY;
            })
        );
    }

    private getAdmins$(id: string): Observable<IAdministratorAppRoleAssignment> {
        return this.userService.getAdministratorsForApplication(id).pipe(
            catchError(error => {
                console.error(error);
                return EMPTY;
            })
        );
    }

    credentialRemaining(endDate: Date): number {
        const now = new Date();
        const diffMs = new Date(endDate).getTime() - now.getTime();
        const diffDays = Math.round(diffMs / (1000 * 3600 * 24));
        return diffDays;
    }

    assignApiAdmin() {
        if (this.apiAdminForm.invalid) {
            return;
        }

        this.assignAdmin(
            this.apiAdminForm.get('email').value,
            'ApiAdmin',
            'Publisher',
            this.apiAdminForm
        );
    }

    assignCustomerAdmin() {
        if (this.customerAdminForm.invalid) {
            return;
        }

        this.assignAdmin(
            this.customerAdminForm.get('email').value,
            'CustomerAdmin',
            'Customer',
            this.customerAdminForm
        );
    }

    assignAdmin(email: string, role: Role, userDescription: string, form: FormGroup) {
        const roleAssignment: ICreateAppRoleAssignment = {
            applicationId: this.id,
            userEmail: email,
            azureAdRoleId: this.application.appRoles.find(x => x.displayName === role).roleId
        };

        this.userService.assignUserToApplication(roleAssignment).pipe(
            catchError(error => {
                console.error(error);
                this.showError(error);
                return EMPTY;
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            () => {
                form.reset();
                this.toastr.success(`${userDescription} assigned successfully`);
                this.refreshApplicationData$.next('Administrators');
            }
        );
    }

    private showError(error: any) {
        if (error.status === 400) {
            this.toastr.error(this.translations.couldNotAddBadRequest);
        } else if (error.status === 404) {
            this.toastr.error(this.translations.userDoesNotExist);
        } else {
            this.toastr.error(this.translations.couldNotAddFailedRequest);
        }
    }

    removeApiAdmin(id: string, roleAssignmentId: string) {
        this.removeAdmin(id, roleAssignmentId, 'Publisher');
    }

    removeCustomerAdmin(id: string, roleAssignmentId: string) {
        this.removeAdmin(id, roleAssignmentId, 'Customer');
    }

    removeAdmin(id: string, roleAssignmentId: string, userDescription: string) {
        this.userService.removeUserFromRole(id, roleAssignmentId).pipe(
            catchError(error => {
                console.error(error);
                this.toastr.error(this.translations.couldNotRevokeAccess);
                return EMPTY;
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            () => {
                this.toastr.success(`${userDescription} removed successfully`);
                this.refreshApplicationData$.next('Administrators');
            }
        );
    }

    onSelectDuration(value: string | null) {
        if (!value) {
            this.passwordCredentialForm.get('endDate').setValue(null);
            return;
        }
        
        const endDate = new Date();
        endDate.setFullYear(endDate.getFullYear() + Number(value));
        this.passwordCredentialForm.get('endDate').setValue(endDate);
    }

    addPasswordCredential() {
        if (this.passwordCredentialForm.invalid) {
            return;
        }

        of({
            applicationId: this.application.applicationId,
            credentials: {
                displayName: this.passwordCredentialForm.value.displayName,
                endDate: this.passwordCredentialForm.value.endDate
            } as ICreatePasswordCredential
        }
        ).pipe(
            switchMap(data => this.appService.addNewClientSecret(data.applicationId, data.credentials)),
            catchError(error => {
                console.error(error);
                this.toastr.error(this.translations.couldNotAddPasswordCredentials);
                return EMPTY;
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            generatedSecret => {
                this.generatedSecret = generatedSecret;
                this.passwordCredentialForm.reset();
                this.isNewSecretCreated = true;
                this.toastr.success('Secret created successfully. Please write it down as this will not be shown again.');
                this.refreshApplicationData$.next('Application');
            }
        );
    }

    secretModal(keyId: string) {
        this.currentKeyId = keyId;
        this.confirmDeletionModal.nativeElement.open();
    }

    cancelConfirmDeletion() {
        this.confirmDeletionModal.nativeElement.close();
    }

    deleteSecret() {
        of(this.currentKeyId).pipe(
            tap(_ => this.isDeleteSecretInProgress = true),
            switchMap(keyId => this.appService.deleteClientSecret(this.id, keyId)),
            catchError(error => {
                console.error(error);
                this.toastr.error(this.translations.couldNotDeleteSecret);
                return EMPTY;
            }),
            finalize(() => {
                this.isDeleteSecretInProgress = false;
                this.confirmDeletionModal.nativeElement.close();
            }),
            takeUntil(this.destroy$)
        ).subscribe(() => {
            this.refreshApplicationData$.next('Application');
            this.toastr.success('Secret deleted successfully');
        });
    }

    private initPasswordCredentialFormEvents() {
        this.passwordCredentialForm.get('duration').valueChanges.pipe(
            takeUntil(this.destroy$)
        ).subscribe(duration => this.onSelectDuration(duration));
    }

    private initI18nData() {
        this.translateService.get('messages').subscribe(data => {
            this.translations = data;
        });
    }

    get customerAdminFormInvalid(): boolean {
        return (this.customerAdminForm.touched || this.customerAdminForm.dirty) && this.customerAdminForm.invalid;
    }

    get apiAdminFormInvalid(): boolean {
        return (this.apiAdminForm.touched || this.apiAdminForm.dirty) && this.apiAdminForm.invalid;
    }

    get passwordCredentialFormInvalid(): boolean {
        return (this.passwordCredentialForm.touched || this.passwordCredentialForm.dirty) && this.passwordCredentialForm.invalid;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
      }
}
