import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SettingsProvider {
    private config: any;
    public configLoaded: ReplaySubject<boolean>;

    constructor(private http: HttpClient) {
        this.configLoaded = new ReplaySubject(1);
    }

    getUrl(url: string): string {
        return environment.urlPrefix + url;
    }

    // public loadConfig(): Promise<any> {
    //     console.log('trying to load config...');
    //     const configEndpoint = this.getUrl('api/configuration');

    //     return this.http
    //         .get(configEndpoint)
    //         .toPromise()
    //         .then(res => {
    //             console.log(res);
    //             this.config = res;
    //             this.configLoaded.next(true);
    //             Promise.resolve();
    //         })
    //         .catch(error => {
    //             this.configLoaded.error(error);
    //             Promise.reject(error);
    //         });
    // }

    public loadConfiguration(): Observable<any> {
        return this.http
            .get(this.getUrl('api/configuration'))
            .pipe(tap(config => {
                this.config = config;
                this.configLoaded.next(config);
            }));
    }

    public get configuration(): any {
        return this.config;
    }
}
