import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { DatePipe } from '@angular/common';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { InputDateValueAccessor } from 'src/directives/input-date-value-accessor';
import { AutocompleteValueAccessor } from '../directives/autocomplete-value-accessor';
import { CheckboxValueAccessor } from '../directives/checkbox-value-accessor';
import { InlineTextValueAccessor } from '../directives/inline-text-value-accessor';
import { InlineTextareaValueAccessor } from '../directives/inline-textarea-value.accessor';
import { InputDateRangeValueAccessor } from '../directives/input-date-range-value-accessor';
import { InputTextValueAccessor } from '../directives/input-text-value-accessor';
import { RadiobuttonValueAccessor } from '../directives/radiobutton-value-accessor';
import { SelectValueAccessor } from '../directives/select-value-accessor';
import { InputSearchValueAccessor } from './../directives/input-search-value-accessor';
import {
    ApplicationCreationComponent
} from "./routes/applications/application-creation/application-creation.component";
import {
    ApplicationDetailsComponent
} from "./routes/applications/application-details/application-details.component";
import { ApplicationsComponent } from "./routes/applications/applications.component";
import { ForbiddenComponent } from "./routes/forbidden/forbidden.component";
import { InvitationsComponent } from "./routes/invitations/invitations.component";
import { InvitationService } from "./services/invitation.service";
import { UserService } from "./services/user.service";
import { SettingsProvider } from "./settingsprovider";
import { AuthenticationInterceptor } from "./shared/authentication/api.interceptor";
import { MsalService } from "./shared/authentication/msal.service";
import { InsightsModule } from "./insights/insights.module";
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { CanActivateInvitations } from './routes/invitations/can-activate-invitations.guard';
import { CanActivateApplications } from "./routes/applications/can-activate-applications.guard";

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        ApplicationsComponent,
        ApplicationDetailsComponent,
        ApplicationCreationComponent,
        InvitationsComponent,
        ForbiddenComponent,
        InputTextValueAccessor,
        CheckboxValueAccessor,
        RadiobuttonValueAccessor,
        InputDateValueAccessor,
        InputSearchValueAccessor,
        AutocompleteValueAccessor,
        InlineTextareaValueAccessor,
        InputDateRangeValueAccessor,
        SelectValueAccessor,
        InlineTextValueAccessor,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        InsightsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 12000
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ClipboardModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeConfiguration,
            deps: [SettingsProvider],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        DatePipe,
        SettingsProvider,
        MsalService,
        UserService,
        InvitationService,
        CanActivateInvitations,
        CanActivateApplications,
        provideHttpClient(withInterceptorsFromDi())
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

export function initializeConfiguration(settingsProvider: SettingsProvider) {
    return (): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            settingsProvider.loadConfiguration().subscribe({
                next: (v) => resolve(v),
                error: (e) => reject()
            })
        });
    };
}
