import { Directive, ElementRef, HostListener, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessor } from './value-accessor';

@Directive({
  /* tslint:disable-next-line:directive-selector */
  selector: 'xds-input-text',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTextValueAccessor,
      multi: true
    }
  ]
})
export class InputTextValueAccessor extends ValueAccessor {
  constructor(injector: Injector, el: ElementRef) {
    super(injector, el);
  }

  @HostListener('xdsChange', ['$event.target'])
  _handleXdsChange(el: any) {
    this.handleChangeEvent(el, el.value);
  }
}
