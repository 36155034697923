import { catchError, combineLatestWith, EMPTY, finalize, map, Observable, of, Subject, switchMap, takeUntil, tap } from "rxjs";
import { ValidateWhiteSpace } from "src/app/common/validators/whitespace.validator";
import { ApplicationService } from "src/app/services/application.service";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';

interface IApplicationCreationValidationResult {
    isApplicationApiNameInvalid: boolean;
    isCustomerInvalid: boolean;
    isEnvironmentInvalid: boolean;
    isDisplayNameInvalid: boolean;
    applicationApiNameInvalidMessage?: string;
    customerInvalidMessage?: string;
    environmentInvalidMessage?: string;
    displayNameInvalidMessage?: string;
}

@Component({
    selector: 'app-application-creation',
    templateUrl: './application-creation.component.html'
})
export class ApplicationCreationComponent implements OnInit, OnDestroy {
    private readonly destroying$ = new Subject<void>();
    applicationForm: FormGroup;

    isLoading: boolean = false;

    // error message displayed above forms
    displayErrorMessage: string = '';

    public option: string;
    public environments: string[] = ['PRD', 'SANDBOX', 'DEV', 'INT', 'SIT', 'UAT', 'ACT', 'PP', 'PERF'];
    public environment: string;
    public customer: string = '';
    public applicationApiName: string = '';

    displayName$: Observable<string>;

    validationResult: IApplicationCreationValidationResult = {
        isApplicationApiNameInvalid: false,
        isCustomerInvalid: false,
        isEnvironmentInvalid: false,
        isDisplayNameInvalid: false,
        applicationApiNameInvalidMessage: 'Application name is required.',
        customerInvalidMessage: 'Company / Customer Name is required.',
        environmentInvalidMessage: 'Environment is required.',
        displayNameInvalidMessage: 'Display Name is incorrect.'
    }

    private displayNameControl: AbstractControl;
    private applicationApiNameControl: AbstractControl;
    private customerControl: AbstractControl;
    private environmentControl: AbstractControl;

    constructor(
        private appService: ApplicationService,
        protected router: Router,
        private formBuilder: FormBuilder,
        private translateService: TranslateService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.applicationForm = this.formBuilder.group({
            displayName:        ['', [Validators.required, ValidateWhiteSpace, Validators.maxLength(90)]],
            applicationApiName: ['', [Validators.required, ValidateWhiteSpace]],
            customer:           ['', [Validators.required, ValidateWhiteSpace]],
            environment:        ['', [Validators.required]]
        });


        this.applicationApiNameControl = this.applicationForm.get('applicationApiName');
        this.customerControl = this.applicationForm.get('customer');
        this.displayNameControl = this.applicationForm.get('displayName');
        this.environmentControl = this.applicationForm.get('environment');

        const applicationApiName$ = this.applicationApiNameControl.valueChanges.pipe(
            map(value => this.onChangeValue(this.applicationApiNameControl, value))
        );

        const customer$ = this.customerControl.valueChanges.pipe(
            map(value => this.onChangeValue(this.customerControl, value))
        );
        
        const environment$ = this.environmentControl.valueChanges;

        this.displayName$ = this.displayNameControl.valueChanges;

        applicationApiName$.pipe(
            combineLatestWith(customer$, environment$),
            takeUntil(this.destroying$)
        ).subscribe(
            ([applicationApiName, customer, environment]) => this.setDisplayName(applicationApiName, customer, environment)
        );

        this.customerControl.valueChanges.pipe(
            takeUntil(this.destroying$)
        ).subscribe(() => this.validateCustomer());

        this.applicationApiNameControl.valueChanges.pipe(
            takeUntil(this.destroying$)
        ).subscribe(() => this.validateApplicationApiName());

        this.displayNameControl.valueChanges.pipe(
            takeUntil(this.destroying$)
        ).subscribe(() => this.validateDisplayName());
    }

    private validateDisplayName(): void {
        const field = this.displayNameControl;

        this.validationResult.isDisplayNameInvalid = field.invalid && (field.hasError('required') || field.hasError('invalidInput') || field.hasError('maxlength'));

        if (this.validationResult.isDisplayNameInvalid) {
            let messages: string[] = [];
            if (field.hasError('maxlength')) {
                const error = field.getError('maxlength');
                messages.push(`Display name is too long. Max length is ${error.requiredLength} but got ${error.actualLength}.`);
            }
            if (field.hasError('required') || field.hasError('invalidInput')) {
                messages.push('Display Name is required.');
            }

            this.validationResult.displayNameInvalidMessage = messages.join(' ');
        }
    }

    private validateCustomer(): void {
        const field = this.customerControl;
        this.validationResult.isCustomerInvalid = field.invalid && (field.dirty || field.touched) && (field.hasError('required') || field.hasError('invalidInput'));
    }

    private validateApplicationApiName(): void {
        const field = this.applicationApiNameControl;
        this.validationResult.isApplicationApiNameInvalid = field.invalid && (field.dirty || field.touched) && (field.hasError('required') || field.hasError('invalidInput'));
    }

    onSubmit() {
        if (this.applicationForm.invalid) {
            this.getErrorMessage('errors.invalidApplication');
            return;
        }

        of({
            displayName: this.displayNameControl.value
        }).pipe(
            tap(_ => this.isLoading = true),
            switchMap(app => this.appService.createApplication(app)),
            catchError(error => {
                console.error(error);
                this.toastr.error(error.statusText);
                return EMPTY;
            }),
            finalize(() => this.isLoading = false),
            takeUntil(this.destroying$)
        ).subscribe(response => {
            console.log(response);
            this.router.navigate(['/applications/' + response.applicationId]);
        });
    }


    // get translation for tips messages
    private getErrorMessage(message: any) {
        this.translateService.get(message).subscribe((text: string) => {
            this.displayErrorMessage = text;
        });
    }

    onChangeValue(control: AbstractControl<any, any>, value: string): string {
        const newValue = value.trim().toUpperCase().replace(/\s/g, '');
        if (newValue !== control.value) {
            control.setValue(newValue);
        }

        return newValue;
    }

    setDisplayName(applicationApiName: string, customer: string, environment: string) {
        this.displayNameControl
            .setValue(
                `CCMS-API-${customer}-${applicationApiName}-${environment}`
            );
    }

    ngOnDestroy() {
        this.destroying$.next(undefined);
        this.destroying$.complete();
    }
}
