import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MsalService } from "./shared/authentication/msal.service";
import { filter, map, of, Subject, switchMap, takeUntil, tap, toArray } from "rxjs";
import { Role } from "./common/custom.types";

export interface Menu {
    name: string;
    link: string;
    icon: string;
    roles: Array<Role>;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private readonly destroying$ = new Subject<void>();
    title = 'ClientApp';
    username: string;
    initials: string;
    isLoggedIn: boolean = false;
    languageOptions = new Array<string>('en', 'de');
    private translations: any;
    roles: string[] = [];
    brand: string;
    theme = "set-midnight-blue-theme";
    brandName = "Munich Re";

    data: Menu[];
    // initial menu item selected
    private currentRouteLink: string = '';

    constructor(
        protected router: Router,
        private translate: TranslateService,
        private msalService: MsalService,
        private route: ActivatedRoute
    ) {
        this.translate.use('en');
        // translate.setDefaultLang(translate.getBrowserLang());

        this.initI18nData();
        this.initials = this.splitName(this.username);
    }

    ngOnInit() {
        this.route.queryParams.pipe(
            takeUntil(this.destroying$)
        ).subscribe((event) => {
            this.brand = event.brand;
            this.changeTheme(this.brand);
        });

        this.isLoggedIn = this.msalService.isOnline();

        if (this.isLoggedIn) {
            this.username = this.msalService.getUser().name;
            this.initials = this.splitName(this.username);
            this.initRole();
        }

        // detect navigation changes to setup the correct selected menu item
        this.router.events.pipe(
            takeUntil(this.destroying$)
        ).subscribe(event => {
            if (event instanceof NavigationEnd) {
                const route = event['url'].slice(1).split('/');
                this.currentRouteLink = this.getCurrentRoute(route[0]);
            }
        });
    }

    private initRole() {
        const invitationsNavMenu: Menu = { name: 'Invitations', link: '/invitations', icon: 'manage_users', roles: ['APIMManager', 'APIAdmin'] };
        const applicationsNavMenu: Menu = { name: 'Applications', link: '/applications', icon: 'basemaps', roles: ['APIMManager', 'APIAdmin', 'CustomerAdmin'] };

        this.msalService.getUserRoles$().pipe(
            toArray(),
            tap(roles => this.roles = roles),
            switchMap(roles =>
                of(invitationsNavMenu, applicationsNavMenu).pipe(
                    filter(menuItem => menuItem.roles.some(role => roles.includes(role))),
                    toArray()
                )
            ),
            takeUntil(this.destroying$)
        ).subscribe({
            next: result => {
                this.data = result;
                if (result.length === 0) {
                    this.router.navigateByUrl('/forbidden');
                }
            }
        });
    }

    private initI18nData() {
        this.translate.get('errors').pipe(
            takeUntil(this.destroying$)
        ).subscribe(data => {
            this.translations = data;
        });
    }

    changeTheme(brand: string) {
        if (brand == "amod") {
            this.theme = "set-cyan-theme";
            this.brandName = "American Modern"
        } else if (brand == "ergo") {
            this.theme = "set-red-theme"
            this.brandName = "ERGO"
        } else if (brand == "dkv") {
            this.theme = "set-dark-green-theme"
            this.brandName = "DKV Seguros"
        } else if (brand == "hsb") {
            this.theme = "set-pale-blue-theme"
            this.brandName = "HSB"
        } else {
            this.theme = "set-midnight-blue-theme"
            this.brandName = "Munich Re"
        }
    }

    changeLanguage() {
        this.translate.use('en');
    }

    onChangeLanguage(event: any, languageOption: string) {
        // onSelectionChange triggers twice for selecting and deselecting the values
        // therefore, we check for user input to be true, so only the clicked on change gets handled
        if (event.isUserInput) {
            if (languageOption === 'de') {
                this.translate.use('de');
            } else if (languageOption === 'en') {
                this.translate.use('en');
            } else {
                // default
                this.translate.use('en');
            }
        }
    }

    /**
     * @private
     * @desc get the first 2 initials of the user
     * @param {string} fullName
     * @returns
     * @memberof AppComponent
     */
    private splitName(fullName: string) {
        return fullName
            ? fullName
                  .split(' ')
                  .map(value => value[0])
                  .join('')
                  .substring(0, 2)
                  .toLocaleUpperCase()
            : '';
    }

    private getCurrentRoute(url: string): string {
        let route: string = '';
        switch (url) {
            case 'invitations':
                route = '/invitations';
                break;
            case 'applications':
                route = '/applications';
                break;
            case '':
                route = '/applications';
        }
        return route;
    }

    isSelected(link: string): boolean {
        return link === this.currentRouteLink;
    }

    /**
     * @desc validates the routes on back
     *          navigates to the desired route
     * @param {string} link
     * @returns
     * @memberof AppComponent
     */
    goToRoute(link: string) {
        this.router.navigateByUrl(link);
    }

    /**
     * @desc redirect to the AZURE login
     * @memberof AppComponent
     */
    login(): void {
        this.msalService.login();
        this.ngOnInit();
    }

    /**
     * @desc logout from AD + remove token from sessionStorage
     * @memberof AppComponent
     */
    logout(): void {
        this.msalService.logout();
        sessionStorage.removeItem('token');
    }

    ngOnDestroy(): void {
        this.destroying$.next(undefined);
        this.destroying$.complete();
    }
}
