<div>
    <xds-card>
        <xds-text variant="h1">{{applicationVM?.displayName}}</xds-text>
        <xds-stack>
            <xds-cluster>
                <div>
                    <xds-text variant="body-m" style="width: 150px;">Application ID:</xds-text>
                    <xds-text variant="body-m">{{applicationVM?.applicationId}}</xds-text>
                </div>
            </xds-cluster>
            <xds-cluster>
                <div>
                    <xds-text variant="body-m" style="width: 150px;">Object ID:</xds-text>
                    <xds-text variant="body-m">{{applicationVM?.objectId}}</xds-text>
                </div>
            </xds-cluster>
        </xds-stack>
    </xds-card>
    <xds-tab-navigation>
        <xds-tab-nav-tab slot="tabs" label="Customers"></xds-tab-nav-tab>
        <xds-tab-nav-panel slot="content">
            <div class="add-customer-publisher">
                <h2>Assign a Customer</h2>
                <form (ngSubmit)="assignCustomerAdmin()"
                      [formGroup]="customerAdminForm" autocomplete="off">
                    <xds-input-text helper-text="Please enter a valid email address" type="email"
                                    formControlName="email"
                                    id="email">
                        Email Address
                    </xds-input-text>
                    <xds-button variant="ghost" type="submit" [disabled]="customerAdminFormInvalid">{{ 'messages.assign' | translate }}</xds-button>
                </form>
            </div>

            <div class="customers-publishers-list" *ngIf="administrators">
                <h2>Customers</h2>
                <div *ngIf="administrators.customerAdmins.length == 0">
                    {{ 'errors.noCustomerAdminFound' | translate}}
                </div>
                <xds-table *ngIf="administrators.customerAdmins.length > 0" zebra-mode="true">
                    <table>
                        <thead>
                            <tr>
                                <th><xds-th content-mode="text">Customer Name</xds-th></th>
                                <th *ngIf="isAdmin"><xds-th content-mode="text">Revoke</xds-th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="isLoading; else customerAdminsLoaded">
                                <td [attr.colspan]="isAdmin ? 2 : 1">
                                    <xds-busy-box></xds-busy-box>
                                </td>
                            </tr>
                            <ng-template #customerAdminsLoaded>
                                <tr *ngFor="let admin of administrators.customerAdmins">
                                    <td>{{admin.principalDisplayName}}</td>
                                    <td *ngIf="isAdmin">
                                        <xds-button variant="primary"
                                                    (click)="removeCustomerAdmin(admin.principalId, admin.id)">
                                            <xds-icon slot="icon" name="delete"></xds-icon>
                                            {{'messages.revokeAccess' | translate }}
                                        </xds-button>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </xds-table>
            </div>
        </xds-tab-nav-panel>
        <xds-tab-nav-tab slot="tabs" label="Publishers"></xds-tab-nav-tab>
        <xds-tab-nav-panel slot="content">
            <div class="add-customer-publisher">
                <h2>Assign a Publisher</h2>
                <form (ngSubmit)="assignApiAdmin()" [formGroup]="apiAdminForm" autocomplete="off">
                    <xds-input-text helper-text="Please enter a valid email address"
                                    type="email"
                                    formControlName="email"
                                    id="email">
                        Email Address
                    </xds-input-text>
                    <xds-button variant="ghost" type="submit" [disabled]="apiAdminFormInvalid">{{ 'messages.assign' | translate }}</xds-button>
                </form>
            </div>
            <div class="customers-publishers-list" *ngIf="administrators">
                <h2>Publishers</h2>
                <div *ngIf="administrators.apiAdmins.length == 0">
                    {{ 'errors.noApiAdminFound' | translate}}
                </div>
                <xds-table *ngIf="administrators.apiAdmins.length > 0" zebra-mode="true">
                    <table>
                        <thead>
                            <tr>
                                <th><xds-th content-mode="text">Publisher Name</xds-th></th>
                                <th *ngIf="isAdmin"><xds-th content-mode="text">Revoke</xds-th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="isLoading; else apiAdminsLoaded">
                                <td [attr.colspan]="isAdmin ? 2 : 1">
                                    <xds-busy-box></xds-busy-box>
                                </td>
                            </tr>
                            <ng-template #apiAdminsLoaded>
                                <tr *ngFor="let admin of administrators.apiAdmins">
                                    <td>{{admin.principalDisplayName}}</td>
                                    <td *ngIf="isAdmin">
                                        <xds-button variant="primary" (click)="removeApiAdmin(admin.principalId, admin.id)">
                                            <xds-icon slot="icon" name="delete"></xds-icon>
                                            {{'messages.revokeAccess' | translate }}
                                        </xds-button>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </xds-table>
            </div>
        </xds-tab-nav-panel>
        <xds-tab-nav-tab slot="tabs" label="Secrets"></xds-tab-nav-tab>
        <xds-tab-nav-panel slot="content">
            <div class="add-customer-publisher">
                <h2>Create a Secret</h2>
                <form [formGroup]="passwordCredentialForm" (ngSubmit)="addPasswordCredential()" autocomplete="off">
                    <xds-input-text helper-text="Please provide a recognisable name for your secret" type="text"
                                    formControlName="displayName" id="displayName">
                        Secret Name
                    </xds-input-text>
                    <xds-select label="How long will the secret be valid for?" formControlName="duration" [options]="durationOptions" />
                    <xds-button variant="ghost" type="submit" class="secret-button" [disabled]="passwordCredentialFormInvalid">
                        {{ 'messages.addNewSecret' | translate }}
                    </xds-button>
                    <div>
                        <div *ngIf="isNewSecretCreated" class="secret-box">
                            {{ 'messages.generatedSecret' | translate}}
                            <div class="secret">
                                <b>{{generatedSecret}}</b> <xds-button icon-only variant="primary" [cdkCopyToClipboard]="generatedSecret" class="copy-button"><xds-icon slot="icon" name="copy"></xds-icon></xds-button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="customers-publishers-list" *ngIf="applicationVM?.passwordCredentials.length > 0">
                <h2>Secrets</h2>
                <xds-table zebra-mode="true">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <xds-th content-mode="text">Name</xds-th>
                                </th>
                                <th>
                                    <xds-th content-mode="text">ID</xds-th>
                                </th>
                                <th>
                                    <xds-th content-mode="text">Start Date</xds-th>
                                </th>
                                <th>
                                    <xds-th content-mode="text">End Date</xds-th>
                                </th>
                                <th>
                                    <xds-th content-mode="text">Delete</xds-th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let credential of applicationVM?.passwordCredentials">
                                <td>{{credential.displayName}}</td>
                                <td>{{credential.keyId}}</td>
                                <td>{{ credential.startDate | date:'medium' }}</td>
                                <td>
                                    <xds-icon color="orange" name="status-warning-1" *ngIf="credential.remainingDays > 0 && credential.remainingDays <= 180"></xds-icon>
                                    <xds-icon color="red" name="status-error-1" *ngIf="credential.remainingDays <= 0"></xds-icon>
                                    {{ credential.endDate | date:'medium' }}{{' (' + credential.remainingDays + ' days)' }}
                                </td>
                                <td>
                                    <xds-button variant="primary" (click)="secretModal(credential.keyId)">
                                        <xds-icon slot="icon" name="delete"></xds-icon>
                                        {{'messages.delete' | translate }}
                                    </xds-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </xds-table>
            </div>

        </xds-tab-nav-panel>
    </xds-tab-navigation>
    <xds-modal headline="Are you sure?" decision-required is-open="false" #confirmDeletion>
        <xds-text slot="content">
            Are you sure you want to delete this secret? This process is unrecoverable, your secret
            will be removed and any applications using it will immediately stop working. <b>
                Proceed at your own
                risk.
            </b>
        </xds-text><br>
        <xds-text slot="content">The key to be deleted is: {{currentKeyId}}</xds-text>
        <xds-button slot="bottom-right" variant="ghost" (click)="deleteSecret()" [disabled]="isDeleteSecretInProgress">Delete</xds-button>
        <xds-button slot="bottom-right" variant="primary" (click)="cancelConfirmDeletion()" [disabled]="isDeleteSecretInProgress">Cancel</xds-button>
    </xds-modal>
</div>
