<div class="frameWrapper">
    <div class="createApplicationButton">
        <a *ngIf="canCreateApplication" routerLink="/applications/createApp"
            routerLinkActive="active">
            <xds-button variant="primary">{{ 'messages.addNewApplication' | translate }}</xds-button>
        </a>
    </div>
    <xds-table zebra-mode="true">
        <xds-table-search label="Search" clear-button="true" position="r"></xds-table-search>
        <table>
            <thead>
                <tr>
                    <th><xds-th content-mode="text">{{ 'messages.name' | translate }}</xds-th></th>
                    <th><xds-th content-mode="text">{{ 'messages.appId' | translate }}</xds-th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="isLoading">
                    <td colspan="2">
                        <xds-busy-box></xds-busy-box>
                    </td>
                </tr>
                <tr *ngFor="let app of applications$ | async">
                    <td><a [title]="'Edit '+ app.displayName" [routerLink]="['/applications/', app.applicationId]">{{app.displayName}}</a></td>
                    <td><a [title]="'Edit '+ app.displayName" [routerLink]="['/applications/', app.applicationId]">{{app.applicationId}}</a></td>
                </tr>
            </tbody>
        </table>
    </xds-table>
    <xds-pager></xds-pager>
</div>
