import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppComponent } from "./app.component";
import {
    ApplicationCreationComponent
} from "./routes/applications/application-creation/application-creation.component";
import {
    ApplicationDetailsComponent
} from "./routes/applications/application-details/application-details.component";
import { ApplicationsComponent } from "./routes/applications/applications.component";
import { ForbiddenComponent } from "./routes/forbidden/forbidden.component";
import { InvitationsComponent } from "./routes/invitations/invitations.component";
import { CanActivateInvitations } from "./routes/invitations/can-activate-invitations.guard";
import { CanActivateApplications } from "./routes/applications/can-activate-applications.guard";

const routes: Routes = [
    {
        pathMatch: 'full',
        path: '',
        component: ApplicationsComponent
    },
    {
        pathMatch: 'full',
        path: 'invitations',
        component: InvitationsComponent,
        canActivate: [ CanActivateInvitations ]
    },
    {
        pathMatch: 'full',
        path: 'applications',
        component: ApplicationsComponent,
        canActivate: [ CanActivateApplications ]
    },
    {
        pathMatch: 'full',
        path: 'applications/createApp',
        component: ApplicationCreationComponent,
        canActivate: [ CanActivateApplications ]
    },
    {
        path: 'applications/:id',
        component: ApplicationDetailsComponent,
        canActivate: [ CanActivateApplications ]
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent
    },
    {
        pathMatch: 'full',
        path: '**',
        component: AppComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule {}
