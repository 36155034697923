import { ValidateWhiteSpace } from "src/app/common/validators/whitespace.validator";
import { IInvitation } from "src/app/models/IInvitation";
import { InvitationService } from "src/app/services/invitation.service";
import { SettingsProvider } from "src/app/settingsprovider";

import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';
import { MsalService } from "src/app/shared/authentication/msal.service";
import { catchError, EMPTY, filter, finalize, from, map, mergeMap, of, Subject, switchMap, takeUntil, tap, toArray } from "rxjs";
import { IInviteSystemConfiguration } from "src/app/common/custom.types";
import { TXdsSelectOption } from "@xds/components";

interface IInvitationValidationResult {
    isFirstNameInvalid: boolean;
    isLastNameInvalid: boolean;
    isEmailInvalid: boolean;
    isApplicationNameInvalid: boolean;
    isRoleNameInvalid: boolean;
    firstNameInvalidMessage?: string;
    lastNameInvalidMessage?: string;
    emailInvalidMessage?: string;
    applicationNameInvalidMessage?: string;
    roleNameInvalidMessage?: string;
}

@Component({
    selector: 'app-invitations',
    templateUrl: './invitations.component.html',
    styleUrls: ['./invitations.component.scss']
})
export class InvitationsComponent implements OnInit {
    private readonly destroying$ = new Subject<void>();
    private inviteSystemsConfiguration: IInviteSystemConfiguration[];

    invitationForm: FormGroup;
    isLoading: boolean;
    private translations: any;

    applicationOptions: string[] = [];
    applicationRoles: TXdsSelectOption[] = [];

    validationResult: IInvitationValidationResult = {
        isFirstNameInvalid: false,
        isLastNameInvalid: false,
        isEmailInvalid: false,
        isApplicationNameInvalid: false,
        isRoleNameInvalid: false,
        firstNameInvalidMessage: 'First Name is required.',
        lastNameInvalidMessage: 'Last Name is required.',
        applicationNameInvalidMessage: 'Application Name is required.',
        roleNameInvalidMessage: 'Role Name is required.'
    }

    private firstNameControl: AbstractControl;
    private lastNameControl: AbstractControl;
    private emailControl: AbstractControl;
    private applicationNameControl: AbstractControl;
    private roleNameControl: AbstractControl;

    constructor(
        private translateService: TranslateService,
        private invitationService: InvitationService,
        settingsProvider: SettingsProvider,
        private toastr: ToastrService,
        private msalService: MsalService
    ) {
        this.inviteSystemsConfiguration = settingsProvider.configuration.inviteSystems;
        this.initI18nData();
    }

    ngOnInit() {
        this.initApplicationNames();
        this.initInvitationForm();
    }

    private initI18nData() {
        this.translateService.get('messages').subscribe(data => {
            this.translations = data;
        });
    }

    private initInvitationForm() {
        this.invitationForm = new FormGroup({
            firstName: new FormControl('', [Validators.required, ValidateWhiteSpace]),
            lastName: new FormControl('', [Validators.required, ValidateWhiteSpace]),
            email: new FormControl('', [Validators.required, ValidateWhiteSpace, Validators.email]),
            applicationName: new FormControl('', [Validators.required]),
            roleName: new FormControl('')
        }, { validators: this.roleNameValidator });

        this.firstNameControl = this.invitationForm.get('firstName');
        this.lastNameControl = this.invitationForm.get('lastName');
        this.emailControl = this.invitationForm.get('email');
        this.applicationNameControl = this.invitationForm.get('applicationName');
        this.roleNameControl = this.invitationForm.get('roleName');

        this.applicationNameControl.valueChanges.pipe(
            takeUntil(this.destroying$)
        ).subscribe(value => {
                this.applicationRoles = this.getRoleNamesForSystem(value);
                this.roleNameControl.reset();
                this.validateApplicationName();
        });

        this.emailControl.valueChanges.pipe(
            takeUntil(this.destroying$)
        ).subscribe(() => this.validateEmail());

        this.firstNameControl.valueChanges.pipe(
            takeUntil(this.destroying$)
        ).subscribe(() => this.validateFirstName());

        this.lastNameControl.valueChanges.pipe(
            takeUntil(this.destroying$)
        ).subscribe(() => this.validateLastName());

        this.roleNameControl.valueChanges.pipe(
            takeUntil(this.destroying$)
        ).subscribe(() => this.validateRoleName());
    }

    private validateEmail(): void {
        const field = this.emailControl;
        this.validationResult.isEmailInvalid = field.invalid && (field.dirty || field.touched) && (field.hasError('required') || field.hasError('invalidInput') || field.hasError('email'));

        if (this.validationResult.isEmailInvalid) {
            let messages: string[] = [];
            if (field.hasError('email')) {
                messages.push('Incorrect email format.');
            }
            if (field.hasError('required') || field.hasError('invalidInput')) {
                messages.push('Email is required.');
            }

            this.validationResult.emailInvalidMessage = messages.join(' ');
        }
    }

    private validateFirstName(): void {
        const field = this.firstNameControl;
        this.validationResult.isFirstNameInvalid = field.invalid && (field.dirty || field.touched) && (field.hasError('required') || field.hasError('invalidInput'));
    }

    private validateLastName(): void {
        const field = this.lastNameControl;
        this.validationResult.isLastNameInvalid = field.invalid && (field.dirty || field.touched) && (field.hasError('required') || field.hasError('invalidInput'));
    }

    private validateApplicationName(): void {
        const field = this.applicationNameControl;
        this.validationResult.isApplicationNameInvalid = field.invalid && (field.dirty || field.touched) && field.hasError('required');
    }

    private validateRoleName(): void {
        this.validationResult.isRoleNameInvalid = this.invitationForm.hasError('roleRequired');
    }

    private roleNameValidator: ValidatorFn = (): ValidationErrors | null => {
        return (this.applicationRoles?.length ?? 0) > 0 && this.roleNameControl?.value === null ? { roleRequired: true } : null;
    };

    private initApplicationNames(): void {
        this.applicationOptions = this.inviteSystemsConfiguration.map(system => system.displayName)
    }

    private newXdsSelectOption(optionValue: string): TXdsSelectOption {
        return {
            value: optionValue,
            text: optionValue
        };
    }

    private getRoleNamesForSystem(systemDisplayName: string): TXdsSelectOption[] {
        let roleNames: TXdsSelectOption[] = [];

        from(this.inviteSystemsConfiguration).pipe(
            filter(system => system.displayName === systemDisplayName && system.roles !== null),
            mergeMap(system => system.roles),
            filter(role => this.msalService.hasAnyRole(role.permittedUserRoles)),
            map(role => this.newXdsSelectOption(role.displayName)),
            toArray()
        ).subscribe({
            next: filteredRoles => roleNames = filteredRoles
        });

        return roleNames;
    }

    onSubmit() {
        if (this.invitationForm.invalid) {
            return;
        }

        of(this.generateInvitation()).pipe(
            tap(() => this.isLoading = true),
            switchMap(invitation => this.invitationService.inviteUser(invitation)),
            catchError(error => {
                console.error(error);
                this.displayInvitationResponseError(error);
                return EMPTY;
            }),
            finalize(() => this.isLoading = false),
            takeUntil(this.destroying$)
        ).subscribe(() => {
            this.toastr.success(this.translations['successfullInvitation']);
            this.invitationForm.reset();
        });
    }

    private displayInvitationResponseError(error: any): void {
        if (error.status === 400) {
            this.toastr.error(this.translations.couldNotInviteBadRequest);
        } else if (error.status === 424 && error.error.includes('BadRequest')) {
            this.toastr.error(this.translations.dependencyFailureBadRequest);
        } else if (error.status === 424 && error.error.includes('NotFound')) {
            this.toastr.error(this.translations.dependencyFailureNotFound);
        } else if (error.status === 424 && error.error.includes('InternalServerError')) {
            this.toastr.error(this.translations.dependencyFailureInternalServerError);
        } else if (error.status === 424) {
            this.toastr.error(this.translations.dependencyFailureUnexpectedError);
        } else {
            this.toastr.error(this.translations.failedRequest);
        }
    }

    private generateInvitation(): IInvitation {
        return {
            email:              this.emailControl.value,
            applicationName:    this.applicationNameControl.value,
            roleName:           this.roleNameControl.value,
            firstName:          this.firstNameControl.value,
            lastName:           this.lastNameControl.value
        };
    }

    ngOnDestroy(): void {
        this.destroying$.next(undefined);
        this.destroying$.complete();
    }
}

