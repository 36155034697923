<div class="myWrapper">
    <h1>{{ 'messages.inviteUserTitle' | translate }}</h1>

    <form class="example-form" [formGroup]="invitationForm" (ngSubmit)="onSubmit()">
        <div>
            <xds-input-text
                id="firstName"
                formControlName="firstName"
                required
                helper-text="First Name"
                type="text"
                [attr.has-error]="validationResult.isFirstNameInvalid"
                [attr.error-text]="validationResult.firstNameInvalidMessage"
            >First Name</xds-input-text>
        </div>
        <div>
            <xds-input-text
                formControlName="lastName"
                required
                helper-text="Last Name"
                type="text"
                [attr.has-error]="validationResult.isLastNameInvalid"
                [attr.error-text]="validationResult.lastNameInvalidMessage"
            >Last Name</xds-input-text>
        </div>
        <div>
            <xds-input-text
                formControlName="email"
                required
                helper-text="Please enter a valid email address"
                type="email"
                [attr.has-error]="validationResult.isEmailInvalid"
                [attr.error-text]="validationResult.emailInvalidMessage"
            >Email</xds-input-text>
        </div>
        <div>
            <xds-select formControlName="applicationName" label="Application" id="application">
                <xds-dropdown-item slot="items" *ngFor="let option of applicationOptions" [attr.value]="option">{{option | translate}}</xds-dropdown-item>
            </xds-select>
        </div>
        <div *ngIf="applicationRoles?.length > 0">
            <xds-select formControlName="roleName" label="Role in Application" id="role" class="role-select" [options]="applicationRoles" />
        </div>
        <xds-button type="submit" variant="primary" class="invite-button" [disabled]="isLoading || invitationForm.invalid">{{ 'messages.inviteUser' | translate }} </xds-button>
    </form>
</div>
