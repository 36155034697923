<div class="container">
  <div class="error-template">
    <h1>403 Forbidden Page</h1>
    <div class="error-details">
      {{ 'errors.forbiddenPage' | translate }}
    </div>
    <div class="error-advise">
      <div>
        {{ 'errors.requestAdministratorAdvise' | translate }}
        <a style="color:#165787;" href="mailto:p0060003969@munichre.com">p0060003969&#64;munichre.com</a>.
      </div>
      <div>
        {{ 'errors.changeAccountsAdvise' | translate }}
      </div>
    </div>
    <div class="error-actions">
      <xds-button (click)="logout()" variant="primary"> {{ 'errors.changeAccounts' | translate }} </xds-button>
    </div>
  </div>
</div>
